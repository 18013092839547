<template>
  <transition name="modal" v-if="visible">
    <div class="wrapper_popup h-screen">
      <div class="header_popup flex justify-between items-center h-14 px-4 pt-2 pb-2"
           :class="{ 'error' : errors.get('products_is_not_available') }">
        <div>
          <a href="javascript:void(0)" @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 400.004 400.004"
                 xml:space="preserve"><path d="M382.688 182.686H59.116l77.209-77.214c6.764-6.76 6.764-17.726 0-24.485-6.764-6.764-17.73-6.764-24.484 0L5.073 187.757c-6.764 6.76-6.764 17.727 0 24.485l106.768 106.775a17.252 17.252 0 0 0 12.242 5.072c4.43 0 8.861-1.689 12.242-5.072 6.764-6.76 6.764-17.726 0-24.484l-77.209-77.218h323.572c9.562 0 17.316-7.753 17.316-17.315 0-9.562-7.753-17.314-17.316-17.314z"/></svg>
          </a>
        </div>
        <span class="text-center" v-text="$root.getKeyWord('your_order')"></span>
        <a href="javascript:void(0)" @click="cleanCart">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 482.428 482.429"
               xml:space="preserve"><path d="M381.163 57.799h-75.094C302.323 25.316 274.686 0 241.214 0c-33.471 0-61.104 25.315-64.85 57.799h-75.098c-30.39 0-55.111 24.728-55.111 55.117v2.828c0 23.223 14.46 43.1 34.83 51.199v260.369c0 30.39 24.724 55.117 55.112 55.117h210.236c30.389 0 55.111-24.729 55.111-55.117V166.944c20.369-8.1 34.83-27.977 34.83-51.199v-2.828c0-30.39-24.723-55.118-55.111-55.118zm-139.949-31.66c19.037 0 34.927 13.645 38.443 31.66h-76.879c3.515-18.016 19.406-31.66 38.436-31.66zm134.091 401.173c0 15.978-13 28.979-28.973 28.979H136.096c-15.973 0-28.973-13.002-28.973-28.979V170.861h268.182v256.451zm34.83-311.568c0 15.978-13 28.979-28.973 28.979H101.266c-15.973 0-28.973-13.001-28.973-28.979v-2.828c0-15.978 13-28.979 28.973-28.979h279.897c15.973 0 28.973 13.001 28.973 28.979v2.828z"/>
            <path
                d="M171.144 422.863c7.218 0 13.069-5.853 13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07-7.217 0-13.069 5.854-13.069 13.07v147.154c-.001 7.217 5.851 13.068 13.069 13.068zM241.214 422.863c7.218 0 13.07-5.853 13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07-7.217 0-13.069 5.854-13.069 13.07v147.154c0 7.217 5.851 13.068 13.069 13.068zM311.284 422.863c7.217 0 13.068-5.853 13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07-7.219 0-13.07 5.854-13.07 13.07v147.154c-.001 7.217 5.853 13.068 13.07 13.068z"/></svg>
        </a>
      </div>
      <div class="content_popup bg-white pb-4">
        <div v-if="hasIncludeSectionsOnCart([11253]) && $root.hotelCode === 'mirotelroomservise'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4">Максимальное
            ожидание Вашего заказа в будние дни составит 60 минут. В пятницу, выходные и праздничные дни доставка займёт
            до 2 часов. Но скорее всего мы Ваш заказ принесем быстрее!
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4">The maximum waiting
            time for your order on weekdays is 60 minutes. On Friday, weekends and holidays, delivery will take up to 2
            hours. But just we Your order will most likely be faster!
          </div>
        </div>
        <div v-if="$root.hotelCode === 'mirotel' || $root.hotelCode === 'mirotelroomservise'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4">
            Чтобы убрать товар из корзины, необходимо нажать на него. 
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4">
            To remove an item from the cart, you must click on it.
          </div>
          <div v-if="this.$root.currentLanguage == 'cn'" class="text-white bg-hotel px-4 pb-4 pt-4">
            要從購物車中刪除商品，您必須單擊它。
          </div>
        </div>
        <div v-if="$root.hotelCode == 'marriottroomservice'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4">Уважаемый Гость! С
            Вами свяжется оператор для подтверждения. Стандартное время исполнения заказа после подтверждения 40-45
            минут. Время исполнения заказа может быть увеличено в период повышенной загрузки. Фотографии содержат
            элементы декора. Подача блюд может отличаться.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4">Dear Guest! The
            operator will contact you for confirmation. The standard order execution time after confirmation is 40-45
            minutes. The order execution time can be increased during the period of increased loading. Photos contain
            decorative elements. Serving may vary.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([13198]) && $root.hotelCode === 'ibiskrasnoyarsk'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white text-center bg-hotel px-4 pb-4 pt-4">Дорогой
            гость, обращаем Ваше внимание, что доставка еды в номер не осуществляется. Заказ будет ожидать Вас в
            ресторане ibis Kitchen.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white text-center bg-hotel px-4 pb-4 pt-4">Dear
            guest, please note that room service is not available. The order will be waiting for you in the ibis Kitchen
            restaurant.
          </div>
        </div>
        <div
            v-if="hasIncludeSectionsOnCart([10722]) || hasIncludeSectionsOnCart([12473]) && $root.hotelCode === 'arkhyz'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white text-center bg-hotel px-4 pb-4 pt-4">Запись
            на процедуры предварительная. Для подтверждения заказа процедуры с Вами свяжется администратор СПА. Оплата
            процедуры производится после согласования с администратором СПА или после оказания услуги.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white text-center bg-hotel px-4 pb-4 pt-4">The
            appointment for the procedures is preliminary. To confirm the order of the procedure, the SPA administrator
            will contact you. Payment for the procedure is made after agreement with the SPA administrator or after the
            provision of the service.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([177184]) && $root.hotelCode === 'skyparkhotel'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white text-center bg-hotel px-4 pb-4 pt-4">
            Минимальный заказ из ресторана 1500 руб. С вами свяжется оператор для подтверждения. Стандартное время
            доставки 30-40 минут. Время выполнение заказа может быть увеличено в период повышенной загрузки.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([190848]) && $root.hotelCode === 'arbatstars-apartments'">
          <div class="text-white text-center bg-hotel px-4 pb-4 pt-4">Все товары в корзине указаны со скидкой 20%</div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([2968]) || hasIncludeSectionsOnCart([190494]) && $root.hotelCode === 'radissonslavyanskaya'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white text-center bg-red-600 px-4 pb-4 pt-4">
            Мы свяжемся с Вами по телефону для подтверждения данного заказа
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white text-center bg-red-600 px-4 pb-4 pt-4">
            We will contact you by phone to confirm this order
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([206703]) && $root.hotelCode === 'courtyardpolyana'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white text-center bg-red-600 px-4 pb-4 pt-4">
            За 5 минут до процедуры, просим Вас подойти на стойку приема и размещения для оплаты спа-услуги.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white text-center bg-red-600 px-4 pb-4 pt-4">
            5 minutes before the procedure, we ask you to come to the reception desk to pay for the spa service.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([10684]) && $root.hotelCode === 'ibiskrasnodar'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4">
            С 15.01.24 отель не предоставляет доставку в номера, все заказы забираются самостоятельно. Заказ можно забрать в ресторане с 6:30 до 22:00, в баре с 22:00 до 6:30.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4">
            Starting from January 15, 2024, the hotel no longer provides room service delivery. All orders are to be picked up by guests themselves. Orders can be collected in the restaurant from 6:30 AM to 10:00 PM and in the bar from 10:00 PM to 6:30 AM.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([2907]) && $root.hotelCode === 'alphasirius'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемые гости! <br>Минимальная сумма заказа составляет 500 рублей.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear guests! <br>The minimum order amount is 500 rubles.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([5736]) && $root.hotelCode === 'luchresort'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемые гости, обращаем Ваше внимание,что подача блюд через службу room service, осуществляется в одноразовой посуде, в связи с техническими особенностями расположения ресторана
            <br>
            Минимальный заказ 1000 рублей. Доставка заказа осуществляется бесплатно.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear Guests, we kindly notice you, that we serve any room service orders in disposable dishes due to technical points.
            <br>
            Minimal order for room service is 1000 rubles. Delivery of the order is free of charge.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([209061]) && $root.hotelCode === 'sofrino-park'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемые Гости! 
           <br>
            Минимальная сумма заказа составляет 500 рублей.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear guests! 
            <br>
            The minimum order amount is 500 rubles.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([208531]) && $root.hotelCode === 'dtgsssr'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемые Гости! 
           <br>
            Минимальная сумма заказа составляет 500 рублей.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear guests! 
            <br>
            The minimum order amount is 500 rubles.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([210814]) && $root.hotelCode === 'nsk-dominarussia'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемый Гость! 
            <br>
            Все цены указаны в рублях и включают 20% НДС. Плата за доставку 10% от стоимости заказа будет добавлена к счету.
            <br>
            К оплате принимаются рубли РФ наличными и кредитными картами основных платежных систем.
            <br>
            Если Вам необходима информация о составе блюд в связи с диетой или аллергией, пожалуйста обратитесь к сотруднику отеля по телефону 212
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear Guest! 
            <br>
            All prices are listed in Russian Rubles and include 20% VAT. 10% delivery charge will be applicable to all orders.
            <br>
            Payment is accepted in Russian Rubles: in cash by major credit cards.
            <br>
            For those with special dietary requirements or allergies who may wish to know about food ingredients used, please ask to speak with one of our Team Members by phone 212
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([7973]) && $root.hotelCode === 'hginov'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Ваш заказ будет продублирован и подтвержден по телефону. Пожалуйста, убедитесь в точности контактного номера для связи.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Your order will be duplicated and confirmed by phone. Please ensure the accuracy of your contact number.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([212126]) && $root.hotelCode === 'omegasirius'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемые Гости! 
           <br>
            Минимальная сумма заказа составляет 500 рублей.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear guests! 
            <br>
            The minimum order amount is 500 rubles.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([212025]) && $root.hotelCode === 'lesglamping'">
          <div v-if="this.$root.currentLanguage == 'ru'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Уважаемые Гости! 
           <br>
            Минимальная сумма заказа составляет 1500 рублей.
          </div>
          <div v-if="this.$root.currentLanguage == 'en'" class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Dear guests! 
            <br>
            The minimum order amount is 1500 rubles.
          </div>
        </div>
        <div v-if="hasIncludeSectionsOnCart([212746]) && $root.hotelCode === 'rimarhotel'">
          <div class="text-white bg-hotel px-4 pb-4 pt-4 text-center">
            Пожалуйста, дождитесь звонка от сотрудника ресторана для подтверждения заказа!
          </div>
        </div>
        <div class="products_in_cart bg-gray-100 p-3 m-3 border rounded-lg"
             :class="[errors.get('products_is_not_available') || errors.get('invalid_product_item_counts') || errors.get('products') ? 'border-red-500' : '']">
          <div class="grid grid-cols-1 divide-y">
            <div class="py-3" v-for="(item, index) in cart.products" :key="item.product.id"
                 @click="changeProduct(item, index)">
              <div class="flex justify-between name_product_in_cart">
                <div class="flex items-center"
                     :class="[errors.get('products.' + item.product.id) ? 'text-red-500' : '']">
                  <div v-text="item.quantity"></div>
                  <div class="ml-2 mr-2 text-xs" style="font-size: 9px">&#10005;</div>
                  <div class="w-full" v-text="$root.getLangText(item.product.title)"></div>
                </div>
                <div class="text-gray-500 w-1/4 flex justify-end">
                  <div v-html="$root.showPrice(item.total_price)"></div>
                </div>
              </div>
              <div class="text-xs text-gray-500"
                   v-if="item.product.property_kit_id !== null && item.product.property && Object.keys(item.product.property).length && Object.values(item.product.property.properties).length > 0">
                <div
                    v-for="(item_property, index) in item.product.property.properties.filter(item => item.value !== '')"
                    :key="`${index}-${item.id}`">
                      <span v-if="item_property.ptype_id == 'select'">
                        <span
                            v-text="$root.getLangText(item_property.attrs.filter(item_attr => item_attr.id === item_property.value)[0].title)"></span>
                      </span>
                  <span v-else-if="item_property.ptype_id == 'checkbox'">
                        <span class="flex gap-2"
                              v-for="item_checkbox in item_property.attrs.filter(item_attr => item_attr.checked)">
                          <span>{{ $root.getLangText(item_checkbox.title) }}</span>
                          <span v-if="item_checkbox.quantity > 1">x</span>
                          <span v-if="item_checkbox.quantity > 1">{{ item_checkbox.quantity }}</span>
                        </span>
                      </span>
                  <span v-else-if="item_property.ptype_id == 'slottime'">
                        {{ item_property.value.date + ' ' + item_property.value.timeStart }}
                      </span>
                  <span v-else-if="item_property.ptype_id == 'date'">
                        {{ $root.formatDate(item_property.value) }}
                      </span>
                  <span v-else-if="item_property.ptype_id == 'calendar'">
                        {{ item_property.value.map(item => $root.formatDate(item)).join(', ') }}
                      </span>
                  <span v-else-if="item_property.ptype_id == 'datetime' && item_property.pcode_id == 'scheduled'">
                          <template v-for="item in item_property.value">{{ item }}<br/></template>
                      </span>
                  <span v-else v-text="item_property.value"></span>
                  <!--<template v-if="item.ptype_id == 'slottime'">
                      <span>{{item.value.date|formatDate}} {{item.value.timeStart}}</span>
                  </template>-->
                </div>
              </div>
            </div>
          </div>

          <div class="pt-3 mt-1 border-t">
            <div class="flex items-center justify-between mb-1"
                 v-if="$root.hotel.discount_price > 0 && !$root.cart_free_delivery && $root.cart_total_price > 0">
              <span class="text-sm" v-text="$root.getKeyWord('discount_price')"></span>
              <span class="text-sm" v-html="$root.showPrice($root.hotel.discount_price)"></span>
            </div>

            <div class="flex items-center justify-between mb-1"
                 v-if="$root.hotel.discount_percent > 0 && !$root.cart_free_delivery && $root.cart_total_price > 0">
              <span class="text-sm" v-if="$root.hotelCode == 'novotellondonblackfriars'">Service charge</span>
              <span class="text-sm" v-else-if="$root.hotelCode == 'arbatstars-apartments'">Доставка в апартаменты</span>
              <span class="text-sm" v-else>{{ $root.getKeyWord('discount_price') }}</span>

              <span class="text-sm" v-html="$root.hotel.discount_percent + '%'"></span>
            </div>

            <div class="base-color flex items-center justify-between" v-if="$root.cart_total_price > 0">
              <span class="text-sm" v-text="$root.getKeyWord('total')"></span>
              <span class="text-sm" v-html="$root.showPrice($root.cart_total_price)"></span>
            </div>
          </div>
        </div>

        <div class="text-red-500 px-4 pb-2 text-xs" v-if="errors.get('products_is_not_available')">
          {{ $root.getKeyWord('products_not_available') }}
        </div>
        <div class="text-red-500 px-4 pb-2 text-xs" v-if="errors.get('products_not_available_time')">
          {{ errors.get('products_not_available_time') }}
        </div>
        <div class="text-red-500 px-4 pb-2 text-xs" v-if="errors.get('invalid_product_item_counts')">
          {{ errors.get('invalid_product_item_counts') }}
        </div>

        <div
            v-if="(hasIncludeSectionsOnCart([11640]) && $root.hotelCode === 'aysletitsnow') || (hasIncludeSectionsOnCart([11402]) && $root.hotelCode === 'ayshotel')">
          <div class="text-white bg-hotel px-4 pb-2 pt-2">
            Минимальная сумма для заказа - 1000 ₽
          </div>
        </div>

        <app-recommended-products :products="$root.cart.products.map(item => item.product)"></app-recommended-products>

        <div class="bg-white p-3 mt-3 pt-4">
          <div class="form_cart">

            <div class="wrapper_select mb-5 form_group" :class="[errors && errors.get('payment') ? 'valid' : '']"
                 v-if="$root.hotel.is_payment_property && $root.cart_total_price > 0 && displayHotelPayments && $root.cart.hotel_payments && $root.cart.hotel_payments.length > 0">
              <div class="flex pb-2">
                <div class="header_option" :class="[ errors && errors.get('payment')  ? 'valid' : '']"
                     v-text="$root.getKeyWord('pay_method')"></div>
                <span class="text-xs text-red-500 -mt-1 px-1"> *</span>
              </div>
              <select class="default_select  appearance-none" v-model="cart.payment">
                <option :value="payment.id" v-for="payment in $root.cart.hotel_payments" :key="payment.id">
                  {{ $root.getLangText(payment.title) }}
                </option>
              </select>
            </div>

            <div class="mb-5 mb-4 form_group" :class="[errors && errors.get('room_number') ? 'valid' : '']"
                 v-if="displayRoomNumber">
              <div class="flex pb-2">
                <div class="header_option" :class="[errors && errors.get('room_number') ? 'valid' : '']"  v-text="roomNumberInputTitle()"></div>
                <span class="text-xs text-red-500 -mt-1 px-1"> *</span>
              </div>
              <div class="option">
                <input type="text" v-model="cart.room_number" class="h-4" title="property_room_number"/>
              </div>
            </div>

            <app-properties-new ref="property" :property="cart.property" :errors="errors"></app-properties-new>

            <div class="form_group mt-5" v-if="$root.hotel.is_comment_property">
              <div class="header_option pb-2" v-text="$root.getKeyWord('comments')"></div>
              <textarea id="message" v-model="cart.comment"></textarea>
            </div>
          </div>

          <div v-if="$root.hotel.is_agreement_text && !$root.showConfirmationCheckBox()">
            <!--<input type="checkbox" v-model="checked_agreement" />-->
            <span class="text-xs text-gray-600 font-light" v-text="$root.getKeyWord('use_session')"></span>
            <span class="text-xs underline text-gray-600 font-light ml-1 cursor-pointer"
                  v-text="$root.getKeyWord('public_offer')"
                  @click="$modal.show('modal-agreement'); $root.getPublicInformation('full_agreement')"></span>
          </div>
          <app-uk-privacy-policy v-if="$root.showConfirmationCheckBox()" :check-box-error.sync="check_box_error"
                                 :check-agreement.sync="cart.is_temporary"></app-uk-privacy-policy>

          <div class="p-3 mt-3 pt-4">
            <button v-if="!loading" class="default_green_btn" @click="createOrder" v-text="$root.getKeyWord('send_to_order')"></button>
            <loading :width="'w-14'" :height="'h-14'" v-else></loading>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import moment from 'moment';
import AppRecommendedProducts from './AppRecommendedProducts';
import AppPropertiesNew from '@/components/article/Properties/index';
import AppUkPrivacyPolicy from './AppUkPrivacyPolicy';
import Loading from '../../components/article/ArticleLoading.vue';
import {CaptchaMixin} from '@/components/article/CaptchaMixin';
import PaymentPageSdk from '@raiffeisen-ecom/payment-sdk';
import {IsAnyProductGotRoomNumberOptionMixin} from '@/components/article/IsAnyProductGotRoomNumberOptionMixin';
import {eventBus} from '../../main';

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  searchByKey(field) {
    return Object.keys(this.errors).filter(item => item.indexOf(field) > -1).length > 0;
  }

  record(errors) {
    this.errors = errors;
  }
}

export default {
  name: 'app_cart',

  mixins: [CaptchaMixin, IsAnyProductGotRoomNumberOptionMixin],

  components: {AppRecommendedProducts, AppUkPrivacyPolicy, PaymentPageSdk, AppPropertiesNew, Loading},

  data() {
    return {
      comment: '',
      checked_agreement: this.$root.showConfirmationCheckBox() ? false : true,
      payment: (localStorage.getItem('fs_payment') != undefined) ? localStorage.getItem('fs_payment') : '',
      room_number: this.getDefaultRoomNumber(),
      status_payment: 'NONE',
      intervalCheckPayment: null,
      visible: false,
      loading: false,
      check_box_error:false,
      errors: new Errors(),
    }
  },

  created() {
    let _this = this;
    eventBus.$on('is-empty-cart', function () {
        _this.cleanCart();
    });
    eventBus.$on('updated-cart-payment', data => {
      if (!this.$root.hotel.payments) return;

      _this.cart.property.properties.forEach(function (prop) {
        if (_this.selectedPayment && _this.selectedPayment.ptype_id !== 'cash' && prop.pcode_id == 'banknotes') {
          prop.value = '';
        }
      });
    });

    eventBus.$on('cart', data => {
      this.$root.updateCart();

      this.cart.payment = this.$root.hotelCode === 'anapa-lazurnyy' ? 779 : null;
      this.cart.comment = '';
      this.cart.is_temporary = false;
      this.cart.room_number = this.getDefaultRoomNumber();

      this.visible = true;
      this.loading = false;

      this.errors = new Errors();
    });
  },

  computed: {
    displayRoomNumber() {
      return this.$root.hotel.is_room_number_property && this.$root.hotel.limit_room.length > 0 && this.isAnyProductGotRoomNumberOption(this.cart.products);
    },

    displayHotelPayments() {
      if (this.$root.hotel.site === 'radissoncollection.2roomz.com') {
        var is_pre_arrival = this.cart.products.filter(item => item.parent_section.id === 10068).length > 0;
        if (is_pre_arrival)
          return false;
      }

      return true;
    },

    selectedPayment() {
      try {
        var payment = this.$root.cart.hotel_payments.filter(item => item.id == this.cart.payment);

        return payment.length > 0 ? payment[0] : null;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    cart() {
      return this.$root.cart;
    }
  },

  watch: {
    cart: {
      handler: function (newValue, oldValue) {
        let newValueTemp = JSON.parse(JSON.stringify(newValue));
        if (newValueTemp.property && newValueTemp.property.properties) {
          newValueTemp.property.properties.forEach(function (property) {
            if (property.pcode_id == 'banknotes') {
              property.value = '';
            }
          });
        }
        localStorage['cart'] = JSON.stringify(newValueTemp);
      },
      deep: true
    },

    visible(val) {
      if (val) {
        let element = document.createElement('div');
        element.classList.add("overlist");
        document.body.appendChild(element);
      } else {
        let elem = document.getElementsByClassName('overlist')[0];
        elem.parentNode.removeChild(elem);
      }
      this.$root.freezePageScroll();
    }
  },

  filters: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    }
  },

  methods: {
    close() {
      this.visible = false;
    },

    cleanCart() {
      this.$root.cleanCart();
      this.$root.updateCart();

      this.visible = false;

      localStorage.removeItem('text_accepted');
    },

    createOrder() {
      if(this.$root.showConfirmationCheckBox() && this.$root.hotel.is_security_hard && !this.cart.is_temporary){
        this.check_box_error = true;
        return
      }

      if (!this.displayRoomNumber) {
          this.cart.room_number = '';
      }

      this.sendOrder();
    },

    sendOrder() {
      this.loading = true;

      // prepare cart for send
      this.cart.uuid = this.$root.generate_and_set_uuid();
      this.cart.hotel_id = this.$root.hotel.id;
      let sendCart = this.$root.buildCart(this.cart);
      sendCart.language = this.$root.currentLanguage;
      sendCart.person_uuid = localStorage.getItem('person-uuid');

      if (!this.displayRoomNumber) {
        this.cart.room_number = ''
      } else {
        localStorage.setItem('fs_room_number', this.cart.room_number);
      }

      // send order to server
      this.$root.yandexGoal('goal - order - start');
      this.$http.post(process.env.VUE_APP_API_URL + 'orders', sendCart)
          .then(({data}) => {

            this.$root.yandexGoal('goal - order - finish');

            if (data.data.order !== undefined && data.data.order.uuid) {
              this.$root.store_order_uuid(data.data.order.uuid);
            }

            if (data.initiator !== undefined && data.initiator) {
              localStorage.setItem('person-uuid', data.initiator.uuid);
            }

            var payment_url = null;
            if (this.selectedPayment && this.selectedPayment.ptype_id === 'online') {
              if (data.data.pay_data !== undefined && data.data.pay_data.response !== undefined) {
                if (data.data.pay_data.response.formUrl !== null)
                  payment_url = data.data.pay_data.response.formUrl;
                this.successOrderPopup(payment_url);
                return data;
              }
            }

            this.successOrderPopup(payment_url);
            this.loading = false;
            localStorage.removeItem('property_values');
            return data;
          }).catch(error => {
        this.loading = false;
        this.errors.record(error.response.data.data);
      });
    },

    successOrderPopup(payment_url = null) {
      // hide cart, display success popup
      this.visible = false;

      eventBus.$emit('order-success-splash', {
        section: this.cart.products[0].section,
        parent_section: this.cart.products[0].parent_section,
        total_price: this.$root.cart_total_price,
        payment_url: payment_url,
      });

      this.$root.cleanCart();
    },

    changeProduct(item, index) {
      eventBus.$emit('product-cart', {
        product: JSON.stringify(item.product),
        section: item.section,
        parent_section: item.parent_section,
        quantity: item.quantity,
        from_recommendation: item.from_recommendation,
        action: {
          type: 'update',
          index_for_update: index,
        }
      });
    },

    getDefaultRoomNumber() {
      if (this.$root.hotelCode == 'palmira-bc') return '';

      return (localStorage.getItem('fs_room_number') != undefined) ? localStorage.getItem('fs_room_number') : '';
    },

    hasIncludeSectionsOnCart(sectionIds) {
      var result = this.cart.products.filter(item => {
        return sectionIds.includes(item.parent_section.id)
      });

      return result.length > 0;
    },
  }
}

</script>
<style>
.wrapper_popup .overlay {
  background: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.multiselect__tags {
  min-height: 34px;
  display: block;
  padding: 8px 40px 0 8px;
  border: 1px solid #F2F2F2;
  background: #fff;
  font-size: 14px;
  height: 34px;
  border-radius: 8px;
}

.multiselect__select:before {
  display: none;
}

.multiselect__input, .multiselect__single {
  line-height: 14px;
}

.grecaptcha-badge {
  right: -1000px !important;
}

div[class^='style_root__'] {
  z-index: 102 !important;
}
</style>